import { Heading, Text } from "@chakra-ui/core"
import React from "react"
import moment from "moment"

type Props = {
  personCount: string
  arriveDay: Date | null
  leaveDay: Date | null
  place: string
}

export const ResultText: React.FC<Props> = ({
  arriveDay,
  leaveDay,
  personCount,
  place,
}) => {
  return place ? (
    <Heading as="h5" fontSize="1.3rem" textAlign="center" fontWeight="300">
      Varaa{" "}
      <Text as="span" fontWeight="600">
        {personCount} henkilölle
      </Text>{" "}
      majoitus{" "}
      <Text as="span" fontWeight="600">
        {moment(arriveDay).format("DD[.]MM[.]YYYY")} -
        {moment(leaveDay).format("DD[.]MM[.]YYYY")}
      </Text>{" "}
      kohteessa{" "}
      <Text as="span" fontWeight="600">
        {place[0].toUpperCase() + place.slice(1)}
      </Text>{" "}
      jossakin seuraavissa palveluissa:
    </Heading>
  ) : (
    <Text>Valitse paikkakunta</Text>
  )
}
