import { Box } from "@chakra-ui/core"
import React from "react"
import { City } from "../../../types/City"
import { ResultCard } from "./resultCard/ResultCard"

type Props = {
  placeName: string
  bookingId: string | null
  lomarengasId: string
  arriveDate: Date | null
  leavingDate: Date | null
  persons: string
  region?: boolean
}

export const ResultContainer: React.FC<Props> = ({
  arriveDate,
  bookingId,
  lomarengasId,
  placeName,
  region,
  leavingDate,
  persons,
}) => {
  return (
    <Box display="flex" flexDir="column" justifyContent="center" m="0rem auto">
      <ResultCard
        region={region}
        bookingId={bookingId}
        lomarengasId={lomarengasId}
        placeName={placeName}
        arriveDate={arriveDate}
        leavingDate={leavingDate}
        persons={persons}
        company="lomarengas"
      />
      <ResultCard
        region={region}
        bookingId={bookingId}
        lomarengasId={lomarengasId}
        placeName={placeName}
        arriveDate={arriveDate}
        leavingDate={leavingDate}
        persons={persons}
        company="booking"
      />
    </Box>
  )
}
