import { Text, Box } from "@chakra-ui/core"
import React from "react"

type Props = { selectedPlace: string }

export const PlaceHighlight: React.FC<Props> = ({ selectedPlace }) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      fontSize={["0.8rem", "1.2rem"]}
    >
      <Text fontWeight="600">Etsi sinulle sopivin majoitus</Text>
      <Text
        fontWeight="900"
        fontSize={["1rem", "1.6rem"]}
        marginLeft={["0.5rem", "1.5rem"]}
        color="#FFB400"
      >
        {selectedPlace}
      </Text>
    </Box>
  )
}
