import { Box, Text } from "@chakra-ui/core"
import React from "react"
// @ts-ignore
import Check from "../../../../assets/check.svg"

type Props = { text: string }

export const CheckBenefit: React.FC<Props> = ({ text }) => {
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Check width="30px" />
      <Text
        fontWeight="700"
        fontSize={["0.8rem", "0.8rem", "0.8rem", "0.8rem", "1rem"]}
        marginLeft="0.2rem"
      >
        {text}
      </Text>
    </Box>
  )
}
