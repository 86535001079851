import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"

type Props = { city: string }

export const SearchImage: React.FC<Props> = ({ city }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        (edge: any) => edge.node.fluid.originalName === "etsimajoitus.png"
      )
      if (!image) {
        return null
      }
      return <Img fluid={image.node.fluid} alt={`Etsi majoitus ${city}`} />
    }}
  />
)
