import { Box, Button, Link, Text } from "@chakra-ui/core"
import React from "react"
// @ts-ignore
import Check from "../../../../assets/check.svg"
import { AffiliateLink } from "../../../buttons/AffiliateLink"
import { CompanyImage } from "../../../images/CompanyImage"
import moment from "moment"
import { City } from "../../../../types/City"
import { trackClick } from "../../../../utils/tracking/trackingHelp"

type Props = {
  company: "trivago" | "booking" | "lomarengas"
  placeName: string
  bookingId: string | null
  lomarengasId: string
  arriveDate: Date | null
  leavingDate: Date | null
  persons: string
  region?: boolean
}

export const ResultCard: React.FC<Props> = ({
  company,
  arriveDate,
  bookingId,
  placeName,
  lomarengasId,
  leavingDate,
  persons,
  region,
}) => {
  const lomarengasFilter = lomarengasId.split("_")[0]

  const correctBookingId = !bookingId
    ? bookingId
    : region
    ? bookingId
    : bookingId[0] === "9"
    ? bookingId
    : -`${bookingId}`

  const type: any = {
    province: "area",
    skicenter: "skiresort",
    municipality: "place",
  }
  const link = {
    trivago: "https://www.trivago.fi/",
    booking: !bookingId
      ? `https://www.booking.com/country/fi.fi.html?aid=1621498&checkin_monthday=${arriveDate?.getDate()}&checkin_month=${
          arriveDate ? arriveDate?.getMonth() + 1 : 0
        }&checkin_year=${arriveDate?.getFullYear()}&checkout_monthday=${leavingDate?.getDate()}&checkout_month=${
          leavingDate ? leavingDate?.getMonth() + 1 : 0
        }&checkout_year=${leavingDate?.getFullYear()}&no_rooms=1&group_adults=${persons}&room1&label=etusivu-tyhja`
      : `https://www.booking.com/searchresults.fi.html?${
          region ? "region" : "city"
        }=${correctBookingId}&aid=1621498&checkin_monthday=${arriveDate?.getDate()}&checkin_month=${
          arriveDate ? arriveDate?.getMonth() + 1 : 0
        }&checkin_year=${arriveDate?.getFullYear()}&checkout_monthday=${leavingDate?.getDate()}&checkout_month=${
          leavingDate ? leavingDate?.getMonth() + 1 : 0
        }&checkout_year=${leavingDate?.getFullYear()}&no_rooms=1&group_adults=${persons}&room1=A&label=kaupunki${placeName}`,
    lomarengas: `https://on.lomarengas.fi/t/t?a=1071150667&as=1318391848&t=2&tk=1&url=https://www.lomarengas.fi/mokkihaku${lomarengasId}?startDate=${moment(
      arriveDate
    ).format("DD[.]MM[.]YYYY")}&endDate=${moment(leavingDate).format(
      "DD[.]MM[.]YYYY"
    )}&persons=${persons}`,
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      bg="#F3F3F3"
      margin="0.5rem auto"
      borderRadius="10px"
      maxW="800px"
      paddingX="1rem"
      paddingY={["2rem", "2rem", "2rem", "0rem"]}
      flexDir={["column", "column", "column", "row"]}
    >
      <Box margin="0.2rem 1rem" justifySelf="flex-start">
        <CompanyImage company={company} />
      </Box>
      <Box
        margin="0.2rem 1rem"
        justifySelf="flex-start"
        fontWeight="800"
        fontSize="1.2rem"
      >
        {company === "lomarengas" ? (
          <Text>Mökit, loma-asunnot</Text>
        ) : (
          <Text>Hotellit, mökit, hostellit</Text>
        )}
      </Box>
      <Box
        margin="0.2rem 1rem"
        justifySelf="flex-start"
        display="flex"
        alignItems="center"
      >
        <Check width="20px" />
        <Text color="#309700" fontWeight="700">
          Ilmainen peruutus
        </Text>
      </Box>
      <Box margin="0.2rem 1rem" justifySelf="flex-start">
        <Link href={link[company]} target="_blank">
          <Button
            cursor="pointer"
            backgroundColor="#006197"
            p="1rem 2rem"
            borderRadius="10px"
            border="none"
            color="white"
            onClick={() =>
              trackClick(
                `vertailu1-${placeName}`,
                `vertailu1Click`,
                `ResultCardClick`
              )
            }
          >
            Näytä tulokset
          </Button>
        </Link>
      </Box>
    </Box>
  )
}
