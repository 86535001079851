import React from "react"
import { Box } from "@chakra-ui/core"

type Props = {}

export const MainContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDir={["column", "column", "column", "column", "row"]}
      p="1rem"
      m={["0rem", "1rem 2rem", "1rem 4rem", "1rem 6rem"]}
    >
      {children}
    </Box>
  )
}
