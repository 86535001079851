import { Box, Button, Select, Text } from "@chakra-ui/core"
import React, { useEffect, useState } from "react"
import { Suggestion } from "../../../types/Suggestion"
import { useDate } from "../../../hooks/useDate"
import { Heading1 } from "../../headings/Heading1"
import { Heading3 } from "../../headings/Heading4"

import ReactDatePicker, { registerLocale } from "react-datepicker"
import { SearchImage } from "../../images/SearchImage"
import { ResultContainer } from "../resultContainer/ResultContainer"
import { CompanyImage } from "../../images/CompanyImage"
import fi from "date-fns/locale/fi"
import { InfoContainer } from "../infoContainer/InfoContainer"
import { PlaceHighlight } from "../infoContainer/items/PlaceHighlight"
import { CheckBenefit } from "../infoContainer/items/CheckBenefit"
import { FiltersContainer } from "../filters/FiltersContainer"
import { AutosuggestName } from "../filters/AutosuggestName"
import { ResultText } from "../resultContainer/texts/ResultText"
import { trackClick } from "../../../utils/tracking/trackingHelp"
registerLocale("fi", fi)

type Props = {
  place: string
  bookingId: string | null
  lomarengasId: string
  region: boolean
}

export const CompareHeader: React.FC<Props> = ({
  children,
  place,
  bookingId,
  lomarengasId,
  region,
}) => {
  const placeCapitalized = place[0].toUpperCase() + place.slice(1)
  const [selectedPlace, setSelectedPlace] = useState(
    place.toLowerCase() === "suomi" ? "" : placeCapitalized
  )
  const [selectedLomarengasId, setSelectedLomarengasId] = useState(lomarengasId)
  const [selectedBookingId, setSelectedBookingId] = useState<string | null>(
    bookingId
  )
  const [isRegion, setIsRegion] = useState(region)
  const [showResults, setShowResults] = useState(false)
  const [personCount, setPersonCount] = useState("")

  const arriveDayHook = useDate()
  const leaveDayHook = useDate()
  const handleChangeDate = (date: Date) => {
    arriveDayHook.changeDate(date)
  }

  const handleShowResults = () => {
    setShowResults(true)
    trackClick(
      `kaikkitaytetty1-${place}`,
      `kaikkitaytetty1Click`,
      `filtersFilled`
    )
  }

  useEffect(() => {
    arriveDayHook.date &&
      leaveDayHook.date &&
      personCount &&
      showResults !== true &&
      handleShowResults()
  }, [arriveDayHook, leaveDayHook, personCount])

  return (
    <Box margin={["3rem 1rem", "3rem 4rem", "3rem 8rem", "3rem 12rem"]}>
      {children}
      <Box marginX={["0.2rem", "0.6rem", "0.8rem", "2.6rem"]}>
        <Heading1>Vertaa ja varaa paras majoitus {placeCapitalized}</Heading1>
        <Heading3>
          Hotellit, mökit, huoneistohotellit, hostellit ja muu majoitus{" "}
          {placeCapitalized}
        </Heading3>
      </Box>
      <InfoContainer>
        <PlaceHighlight selectedPlace={selectedPlace} />
        <CheckBenefit text="Yhdellä haulla suosituimmat palvelut" />
        <CheckBenefit text="Helppo ja nopea" />
      </InfoContainer>
      <FiltersContainer>
        <AutosuggestName
          selectedPlace={selectedPlace}
          setSelectedBookingId={setSelectedBookingId}
          setSelectedLomarengasId={setSelectedLomarengasId}
          setSelectedPlace={setSelectedPlace}
          setIsRegion={setIsRegion}
        />
        <ReactDatePicker
          locale="fi"
          placeholderText="Tulopäivä"
          selected={arriveDayHook.date}
          onChange={(date: Date) => handleChangeDate(date)}
          selectsStart
          minDate={new Date()}
          startDate={arriveDayHook.date}
          css=""
        />
        <ReactDatePicker
          locale="fi"
          selected={leaveDayHook.date}
          onChange={(date: Date) => leaveDayHook.changeDate(date)}
          placeholderText="Lähtöpäivä"
          selectsEnd
          startDate={arriveDayHook.date}
          endDate={leaveDayHook.date}
          minDate={arriveDayHook.date}
        />
        <Select
          placeholder="Montako henkilöä?"
          backgroundColor="#F4F4F4"
          color="black"
          border="1px solid #E5E5E5"
          borderColor="#E5E5E5"
          margin="1rem"
          outline="none"
          borderRadius="10px"
          fontWeight="800"
          height="40px"
          fontSize="0.9rem"
          paddingLeft="2rem"
          width="250px"
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
            setPersonCount(event.target.value)
          }
        >
          <option value="1">1 henkilö</option>
          <option value="2">2 henkilöä</option>
          <option value="3">3 henkilöä</option>
          <option value="4">4 henkilöä</option>
          <option value="5">5 henkilöä</option>
          <option value="6">6 henkilöä</option>
          <option value="7">Yli 6 henkilöä</option>
        </Select>
        <Button
          bg="#00AEE0"
          border="none"
          fontSize="1.3rem"
          color="#04294F"
          minW="130px"
          minH="45px"
          borderRadius="10px"
          onClick={() => setShowResults(true)}
        >
          Etsi
        </Button>
      </FiltersContainer>
      <Box m="1rem auto" textAlign="center">
        {!showResults ? (
          <Box maxW="450px" m="0rem auto">
            <SearchImage city={placeCapitalized} />
          </Box>
        ) : (
          <Box>
            <ResultText
              arriveDay={arriveDayHook.date}
              leaveDay={leaveDayHook.date}
              personCount={personCount}
              place={selectedPlace}
            />
            <ResultContainer
              region={isRegion}
              arriveDate={arriveDayHook.date}
              leavingDate={leaveDayHook.date}
              persons={personCount}
              //TODO: BookingID ei ole tällä hetkellä dynaaminen, pitää lisätä suggestionseihin
              bookingId={selectedBookingId}
              lomarengasId={selectedLomarengasId}
              placeName={selectedPlace}
            />
          </Box>
        )}
        <Text fontWeight="700" fontSize="1.2rem">
          Aloita majoituspaikan etsintä yläpuolelta
        </Text>
        <Text fontWeight="400" fontSize="1.1rem">
          Vertailussa mukana
        </Text>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          maxW="450px"
          m="0rem auto"
        >
          <CompanyImage company="booking" />
          <CompanyImage company="lomarengas" />
          <CompanyImage company="trivago" />
        </Box>
      </Box>
    </Box>
  )
}
