import { Box, Text } from "@chakra-ui/core"
import { Link } from "gatsby"
import React from "react"

type Props = {
  place: string
  link: string
}

export const SuggestItem: React.FC<Props> = ({ place, link }) => {
  const replaceA = new RegExp("\\ä", "g")
  const replaceO = new RegExp("\\ö", "g")
  const formattedLink = link.replace(replaceA, "a").replace(replaceO, "o")
  return (
    <Link to={formattedLink}>
      <Box
        borderRadius="10px"
        m="1rem"
        textAlign="center"
        padding="0.5rem 2rem"
        bg="action"
      >
        <Text color="white">{place[0].toUpperCase() + place.slice(1)}</Text>
      </Box>
    </Link>
  )
}
