import { Box } from "@chakra-ui/core"
import React from "react"

type Props = {}

export const InfoContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box
      display="flex"
      flexDir={["column", "column", "column", "row"]}
      justifyContent="space-evenly"
      alignItems="center"
      marginTop={["1rem", "3rem"]}
    >
      {children}
    </Box>
  )
}
