import React from "react"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Box } from "@chakra-ui/core"

type Props = { company: string }

export const CompanyImage: React.FC<Props> = ({ company }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid {
                ...GatsbyImageSharpFluid
                originalName
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.allImageSharp.edges.find(
        (edge: any) => edge.node.fluid.originalName === `${company}.png`
      )
      if (!image) {
        return null
      }
      return (
        <Box width="126px">
          <Img fluid={image.node.fluid} alt={`Vertaile majoitus ${company}`} />
        </Box>
      )
    }}
  />
)
