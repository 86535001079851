import { Box, Divider, Link, Tag, Text } from "@chakra-ui/core"
import React from "react"

export const CVad = () => {
  return (
    <Box color="blue" textAlign="center">
      <Divider />
      <Box paddingLeft="0.3rem" marginY="0.3rem">
        <Link href="https://www.cvpohjat.fi/" target="_blank">
          <Box>
            <Box
              backgroundColor="green"
              m="0rem auto"
              p="1rem"
              width="130px"
              borderRadius="3px"
            >
              Mainos
            </Box>

            <Text>
              Luo upea CV pohja osoitteessa Cvpohjat.fi {"    "} {">>>"}
              {"    "}
              <Text as="span" fontWeight="500">
                cvpohjat.fi
              </Text>
            </Text>
          </Box>
        </Link>
      </Box>
      <Divider />
    </Box>
  )
}
