import { Box, Heading } from "@chakra-ui/core"
import React from "react"

type Props = {}

export const FiltersContainer: React.FC<Props> = ({ children }) => {
  return (
    <Box
      p="1rem"
      bg="#FFB400"
      borderRadius="10px"
      // flexDir={["column", "column", "column", "column", "row"]}
    >
      <Heading
        as="h5"
        color="white"
        textAlign="center"
        paddingY="0.2rem"
        marginY="0rem"
        fontSize="1.2rem"
      >
        Hae majoitus
      </Heading>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        {children}
      </Box>
    </Box>
  )
}
