export const trackClick = (
  label: string | undefined,
  category: string | undefined,
  event: string | undefined
) => {
  typeof window !== "undefined" &&
    // @ts-ignore
    window.gtag("event", event ? event : "eventti", {
      event_label: label ? label : "klikki",
      event_category: category ? category : "kategoria",
    })
}
