import React, { useState } from "react"
import Autosuggest from "react-autosuggest"
import placeSuggestions from "../../../data/placeSuggestions.json"
import { Suggestion } from "../../../types/Suggestion"

type Props = {
  selectedPlace: string
  setSelectedPlace: (place: string) => void
  setIsRegion: (region: boolean) => void
  setSelectedLomarengasId: (id: string) => void
  setSelectedBookingId: (id: string | null) => void
}

export const AutosuggestName: React.FC<Props> = ({
  selectedPlace,
  setSelectedPlace,
  setSelectedLomarengasId,
  setSelectedBookingId,
  setIsRegion,
}) => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])

  const getSuggestions = (value: string) => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : placeSuggestions.filter(
          place => place.name.toLowerCase().slice(0, inputLength) === inputValue
        )
  }
  const getSuggestionValue = (suggestion: Suggestion) => suggestion.name

  const renderSuggestion = (suggestion: Suggestion) => (
    <div>
      {suggestion.name[0].toUpperCase()}
      {suggestion.name.slice(1)}
    </div>
  )
  return (
    <Autosuggest
      highlightFirstSuggestion={true}
      inputProps={{
        placeholder: "Minne olet menossa?",
        autoComplete: "abcd",
        name: "place",
        id: "place",
        value: selectedPlace,
        onChange: (_event, { newValue }) => {
          setSelectedPlace(newValue)
        },
      }}
      suggestions={suggestions}
      onSuggestionsFetchRequested={({ value }) => {
        if (!value) {
          setSuggestions([])
          return
        }
        setSuggestions(getSuggestions(value))
      }}
      onSuggestionsClearRequested={() => {
        setSuggestions([])
      }}
      onSuggestionSelected={(event, { suggestion, method }) => {
        if ((method = "enter")) {
          event.preventDefault()
        }
        setSelectedPlace(
          suggestion.name[0].toUpperCase() + suggestion.name.slice(1)
        )
        setSelectedLomarengasId(suggestion.lomarengasId)
        setSelectedBookingId(suggestion.bookingId)
        setIsRegion(suggestion.region)
      }}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
    />
  )
}
