import { Box } from "@chakra-ui/core"
import React from "react"
import { Heading3 } from "../headings/Heading3"
import { SuggestItem } from "./item/SuggestItem"

type Props = {
  places: { name: string; link: string }[]
  title: string
}

export const SuggestContainer: React.FC<Props> = ({ places, title }) => {
  const suggestions = places.map(({ link, name }, i) => (
    <SuggestItem key={i} link={link} place={name} />
  ))
  return (
    <Box>
      {places.length > 0 && <Heading3>{title}</Heading3>}
      <Box display="flex" flexWrap="wrap" justifyContent="center">
        {suggestions}
      </Box>
    </Box>
  )
}
